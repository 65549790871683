import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    if (this.element.classList.contains('loading')) {
      this.fetch_interval = setInterval(() => {
        this.fetch_image_url()
      }, 5000);
    }
  }

  async fetch_image_url() {
    const response = await get(this.urlValue, { responseKind: 'json' })
    if (response.ok) {
      response.json.then((value) => {
        if (this.element.querySelector('source[type="image/webp"]')) {
          this.element.querySelector('source[type="image/webp"]').srcset = value.webp_url
        }
        this.element.querySelector('img').src = value.image_url
        this.element.classList.remove('loading')
        clearInterval(this.fetch_interval)
      })
    }
  }

  disconnect() {
    clearInterval(this.fetch_interval)
  }
}
